body {background-color: #F3F7F8 !important ;}
.nav-tabs, .nav-link.active {border-color: #D0DADD #D0DADD #D0DADD !important}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #0F262E;
  background-color: #F3F7F8;
  border-color:#D0DADD #D0DADD #000
}
.header {
  text-align: left;
}
.mainNavigationBar .nav-link {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size:14px;
  color:#fff !important;
}

.mainNavigationBar  .dropdown-item {
  font-size:14px;
}

.mainNavigationBar .dropdown-menu {right:0; left:auto;}

@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem;
      margin-left: 2rem;
  }
}

.btn svg {
  margin-top:-3px;
}
.breadcrumb {font-size:13px;}
.breadcrumb svg {margin-top:-2px;}

small {font-size:60%}
.giant .btn {width:19%;height:130px;}
.giant .btn svg {font-size:4em; padding:10px;}

.vaultCheckList svg {color:green; font-size:1.2em;}
.invalid-tooltip p {margin-bottom:0px;}

.modal-title.h4 {font-size:1em}

.bg-tips {background-color:#042736 !important;}
.bg-tips .card-header {border-bottom: 1px solid #fff;}
.bg-tips .card-body {font-size:.875rem}

.bg-lightBlue {background: linear-gradient(45deg,#39f,#2982cc)!important; border-color:#2982cc!important}
.bg-lightOrange {background:linear-gradient(45deg,#f9b115,#f6960b)!important; border-color: #f6960b!important;}
.bg-lightGreen {background: linear-gradient(45deg,#2eb85c,#1b9e3e)!important; border-color:#1b9e3e!important}
.bg-lightRed {background: linear-gradient(45deg,#e55353,#d93737)!important; border-color:#d93737!important}
.bg-darkPurple {background: #321fdb!important}



.btn-group {width: 100%}
.toggleSelector.btn-group label {font-size:.875em;}

.connectionToggle .beneficiary.active {background-color: aqua;}

.btn-icon {background-color: transparent;  border: none;padding-top:3px;}

.w-20 { width: 20% !important; }

.badge {padding:.75em 1em; margin:.25em}

.connectionToggle label {padding-top:15px}
.fileList {padding:5px;}

.card-footer {border-top:none;}
.card-header {border-bottom:none;}
.assetCard.account {border:2px solid #C30000;}
.assetCard.property {border:2px solid #42CD96;}
.assetCard.insurance {border:2px solid #F96B02;}
.assetCard.property {border:2px solid #42CD96;}
.assetCard.collectibles {border:2px solid #FFAB06;}
.assetCard.vehicle {border:2px solid #3C3681;}

.assetCard .card-header svg.assetTypeIcon, .peopleCard .card-header svg {float:right; color:rgb(33,37,41, .35); font-size:1.3rem;}
.assetCard .card-body .badge {margin-bottom: .5em; padding:.75em .9em;}

.dropzone {
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: medium;
  border-color: #eee;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border .24s ease-in-out;
  border-radius: initial;
}

.circle-icon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 8px;
  color:#fff;
}
.tips.circle-icon {background-color: #fff;color:#FFAB06}
.assetCard.account .circle-icon {background-color: #C30000;}
.assetCard.property .circle-icon {background-color: #42CD96;}
.assetCard.insurance .circle-icon {background-color: #F96B02;}
.assetCard.collectibles .circle-icon {background-color: #FFAB06;}
.assetCard.vehicle .circle-icon {background-color: #3C3681;}
.assetCard .card-header div {float:left;margin-top:-1px;}
.assetCard .circle-icon {float:left;margin-right:5px;}
.dotted .card {border-style: dotted !important;}
.assetCard .smallerAvatar {width:45px;float:left;margin-right:5px;margin-left:-10px;}

.peopleCard .card-header .peopleNameAndTag {margin-top:12px;margin-left:5px;font-weight:bold};
.peopleCard .card-header .sb-avatar {float:left;margin-right:5px;margin-left:-5px;}
.clientCard .card-header .sb-avatar {float:left;margin-right:5px;margin-left:-5px;}
.peopleCard .alittlebitofcss .sb-avatar {float:left;margin-right:5px;margin-left:-5px;}
.clientCard .alittlebitofcss .sb-avatar {float:left;margin-right:5px;margin-left:-5px;}
.peopleCard .alittlebitofcss.nav-tabs {font-size:.8em !important}
.card .card-body .card-text.moreCSS{font-size:.8em !important}
.smallerAvatar {width:45px;float:left;margin-right:5px;margin-left:-10px;}
.smallerAvatarSmaller{width:30px;float:left;margin-right:5px;margin-left:-10px;}
.specialAccountCircle{float:left;margin-right:10px;width:50px;background-color:#5b5370;color:#fff;height:54px;border-radius:50%;line-height:100px;vertical-align:middle;padding:8px;display:flex;align-items:center;justify-content:center;}
.ghosted.card {opacity: .5;}
.signupLogo{ width:120px; text-align:center;}
.LoginPrompt { font-size: 1.3em;}
.floatTopRight {top:5px;right:2px;position: absolute;}